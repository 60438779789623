<template>
  <div>
    <slot />
  </div>
</template>

<script setup>
import { useGlobalStore } from "~/stores/global";

const globalStore = useGlobalStore();

addAnalytics(globalStore.siteSettings.google_analytics_4_id);
addGTMAnalytics(globalStore.siteSettings.google_tag_manager_id);
</script>
